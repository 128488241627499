<template>
  <div class="company-detail">
    <section class="company-detail__main">
      <JobWantedHead />
      <header class="company-info flex">
        <el-image :src="jobInfo.pic" alt="" />
        <div class="title">
          <h4 class="company-name">{{ jobInfo.chain_name }}</h4>
          <div>
            {{ jobInfo.grade }} · {{ jobInfo.principal }} ·
            {{ jobInfo.contact_way }}
          </div>
        </div>
      </header>
      <el-row>
        <el-col
          class="company-detail__center"
          :span="18"
          style="padding-right: 20px"
        >
          <section class="card">
            <h3 class="title">公司简介</h3>
            <div>
              <p>{{ jobInfo.introduce }}</p>
            </div>
          </section>
          <section class="card">
            <h3 class="title mb-1">公司证照</h3>
            <el-image :src="jobInfo.business_license" alt="" />
          </section>
        </el-col>
        <el-col :span="6" class="info-card">
          <header class="info-card-header">公司地址</header>
          <div class="info-card-content">
            <div>{{ jobInfo.address }}</div>
            <!-- <div class="center">
              <div>有效期: {{ jobInfo.validity }}</div>
              <div>创建时间: {{ jobInfo.created }}</div>
            </div> -->
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import JobWantedHead from "@/components/JobWantedHead.vue";

import { getJobChain } from "@/request/api";

export default {
  name: "CompanyDetail",
  components: {
    JobWantedHead,
  },
  data() {
    return {
      jobInfo: {},
    };
  },
  created() {
    getJobChain(this.$route.query.user_ai_chain_id).then((res) => {
      console.log(res, "res");
      if (res.status === 200) {
        this.jobInfo = res.data.data;
      }
    });
  },
  methods: {
    sendExit() {
      this.$emit("update-value");
    },
    goHome() {
      // 返回首页
      this.$router.push("/").catch((e) => {
        console.error(e);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.company-detail {
  height: 100%;
  overflow: auto;
  .company-detail__main {
    // width: 1280px;
    margin: 0 auto;
  }
  .company-info {
    margin-top: 0.5em;
    margin-bottom: 20px;
    padding: 0.5em 3em;
    background: #deebfc;
    border-radius: 10px;
    .el-image {
      width: 90px;
      height: 90px;
    }
    .title {
      margin: 1em;
      .company-name {
        font-size: 22px;
        margin-right: 2em;
        margin-bottom: 0.6em;
      }
    }
  }

  .company-detail__center {
    .card {
      padding: 1em 3em;
      background: #fff;
      border-radius: 16px;
      margin-bottom: 20px;

      .title {
        margin-bottom: 1em;
      }

      p {
        line-height: 2;
      }
    }
  }

  //  右侧公司信息
  .info-card {
    font-size: 14px;
    line-height: 2;
    .center {
      margin-top: 10px;
      width: 100%;
      height: 300px;
      background: skyblue;
    }
  }
}
</style>
