<template>
  <div class="recommend-head">
    <div class="head-left">
      <el-image
        class="head-icon"
        :src="require(`@/assets/image/work.png`)"
        fit="contain"
      ></el-image>
      <div class="head-title">AI求职</div>
    </div>
    <div class="head-right">
      <div class="right-title">
        <span class="title" @click="goToJobHome">求职首页</span>
        <span class="title" @click="onToBack">上一页</span>
        <span class="title" @click="goToWorkChat">消息</span>
      </div>
      <el-image
        class="exit"
        @click="sendExit"
        :src="require(`@/assets/image/exit2.png`)"
        fit="contain"
      ></el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    sendExit() {
      this.$router.push("/");
    },
    goToJobHome() {
      this.$router.push("workSchoolRecommend");
    },
    onToBack() {
      this.$router.back();
    },
    goToWorkChat() {
      this.$router.push("workChat");
    },
  },
};
</script>
<style lang="less">
.recommend-head {
  height: 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .head-right {
    width: 300px;
    height: 100%;
    padding-right: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-title {
      width: 60%;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #2a6ee9;
      font-weight: bold;

      .title {
        border-bottom: 1px solid #2a6ee9;
        cursor: pointer;
      }
    }

    .exit {
      width: 70px;
      cursor: pointer;
    }
  }

  .head-left {
    display: flex;
    align-items: center;

    .head-icon {
      width: 40px;
      margin-right: 6px;
    }

    .head-title {
      font-size: 14px;
      color: #7a7c7e;
    }
  }
}
</style>
